<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2020-12-03 09:59:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="preview-news">
    <div class="main">
      <div class="left">
        <h3>【壮丽70年·奋斗新时代】星城闪耀“未来之光</h3>
        <div class="news-setting">
          <h3 class="time">
            发布时间： 2019-05-20
          </h3>
          <!-- <h5 class="num">
            浏览量： 28289
          </h5> -->
        </div>
        <div class="img-box" style="margin-top:40px;display: flex;justify-content: center;">
          <img src="../../assets/images/news/news8/news1.png" style="width:345px;text-align:center" alt="">
        </div>
        <div class="news-content" style="margin:40px 0">
          <p style="text-align:justify;">
            长沙市人均GDP超过13万元，在全国大城市中靠前；长沙市获国家授牌智能制造示范企业和项目27个，居省会城市第一；近3年来，长沙市人口净流入年均超过20万人，人口年均净增长超过3%。
          </p>
          <p style="text-align:justify;">
            长沙市的“知情人士”说，了解长沙市发展质量，需要关注上述3个数据。数据有些抽象，但沿着岳麓山国家大学科技城—长沙高新区—湘江新区—马栏山视频文创产业园—长沙经开区一路走过来，便能真切触摸到高质量发展的“长沙质感”。
          </p>
          <p style="text-align:justify;">
            比如，“无人驾驶公交车”穿行在智能网联汽车测试区构建的场景，使得这座以古天文学星座命名的省会城市，俨然闪耀着“未来之光”；岳麓山大学科技城萌动的，是“敢为天下先”的深厚湖湘精神与现代科技创新精神深度融合后的创新爆发力……
          </p>
          <p style="text-align:justify;">
            共享，融合，协同，产业链，平台，生态……这些“关键词”频频出现在受访的官员、企业家、学者的口中。4月30日上午，湖南省委常委、长沙市委书记胡衡华接受媒体采访时，简洁地勾勒出以智能化推动制造业高质量发展的路线图——“产业智能化，智能产业化”。
          </p>

          <p style="text-align:justify">
            “惟楚有材，于斯为盛”
          </p>
          <p style="text-align:justify;">
            岳麓山下，桃子湖路，青瓦红墙、错落有致的建筑。
          </p>
          <p style="text-align:justify;">
            这是岳麓山国家大学科技城的一部分，湖南大学机器人学院坐落其中。
          </p>
          <p style="text-align:justify;">
            站在机器人学院展馆门前，副院长黎福海向记者描述：背靠凤凰山，前临桃子湖，右倚天马山，两山一湖的沉静与开阔，很适合展开科技的遐想。
          </p>
          <p style="text-align:justify;">
            展厅中，博士生刘理正在调试由他所带领的团队研发出的智能机器人，这款可服务于仓储物流、货物转运的智能机器人，从设计定型到三次优化调整，仅用了一年多的时间，目前已经与多家企业达成合作意向。
          </p>
          <p style="text-align:justify;">
            “大学是传授知识的殿堂，是创新创业的沃土。”2017年，湖南省委书记杜家毫调研大科城时提出，要充分调动政府、高校、企业创新创业的积极性，把广大师生、科研成果与市场更好地对接起来。
          </p>
          <p style="text-align:justify">
            科研、技术与市场的“围墙”被打破。
          </p>
          <p style="text-align:justify;">
            坐拥20多所高校院所、57个国家和省部级重点实验室、40余名两院院士、30余万名在校大学生、10余万名科研人员，岳麓山下的大科城已成为湖南实施创新引领的主阵地。人工智能、检验检测、新材料等战略性新兴产业的精准导入，大科城形成环后湖知识经济圈、中南大学创新经济走廊、桃子湖高校双创街、西湖互联网58小镇和岳麓高新区“一圈、一廊、一街、一镇、一区”的科创经济发展新格局。
          </p>
          <p style="text-align:justify;">
            走进中南大学科技园研发总部5号楼，湖南新云网科技有限公司演示了智慧健康厅、智慧手表、智慧校牌、智慧课桌、智慧黑板……张尧学院士率队研发、曾获国家自然科学奖一等奖的“透明计算”技术，在这里实现了从实验室走进百姓生活的“创新落地”，在市场上得到了广泛应用。
          </p>
          <p style="text-align:justify;">
            “惟楚有材，于斯为盛。”千年学府岳麓书院的这幅名联，随着大科城的诞生、发展而彰显出“先见之明”。
          </p>
          <p style="text-align:justify;">
            集约人才与智慧，大科城的崛起是窥探长沙布局“智造之城“的点睛之处。
          </p>
        </div>
        <div class="pagination">
          <div class="a-more">
            <span @click="$router.push('/indexOs/newsDetails7')">上一条：长沙市这所小学打造智慧校园，极具未来感</span>
            <span @click="$router.push('/indexOs/newsDetails9')">下一条：岳麓山国家大学科技城：23平方公里的产学研高地</span>
          </div>
          <el-button plain @click="backGo()">
            返回
          </el-button>
        </div>
      </div>
      <!-- 搜索区域 -->
      <!-- <div class="search-right">
        <el-input
          v-model="keyWord"
          placeholder="查询你喜欢的内容"
          suffix-icon="el-icon-search"
        />
        <div class="hot-news">
          <div class="hot-title">
            <span>最热文章</span>
            <i class="hot-icon" />
          </div>
          <div class="lb-news">
            <router-link class="dg-news" to="/indexOs/newsDetails1">
              <img src="../../assets/images/news/news2-1.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  中共中央政治局常委、国务院副总理 韩正来我公司视察
                </h3>
                <h5 class="time">
                 2020-06-01
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails2">
              <img src="../../assets/images/news/news2-2.png">
              <div class="news-introduce">
                <h3 class="news-title">
                    岳麓山大学科技城：强化科技人才支撑 打造湖南创新...
                </h3>
                <h5 class="time">
                  2020-06-03
                </h5>
              </div>
            </router-link>
            <router-link class="dg-news" to="/indexOs/newsDetails3">
              <img src="../../assets/images/news/news2-3.png">
              <div class="news-introduce">
                <h3 class="news-title">
                  大科城软件产业联盟拍了拍
                </h3>
                <h5 class="time">
                  2020-08-03
                </h5>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewNews',
  data() {
    return {
      keyWord: ''
    };
  },
  methods: {
    backGo() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
.preview-news{
  width: 100%;
  height: auto;
  background: #F5F5F5;
  .main{
    width: 800px;
    height: auto;
    margin: 0 auto;
    display: flex;
    padding-top: 58px;
    justify-content: space-between;
    .left{
      flex: 1;
      margin-right: 63px;
      h3{
        color: #313131;
        font-size:24px;
        font-weight: bolder;
        text-align: center;
      }
      .news-setting{
        color: #B5B5B5;
        display: flex;
         font-size:18px;
        .time{
          font-size:18px;
          color: #B5B5B5;
          font-weight: 100;
          width: 100%;
          text-align: center;
        }
        .num{
          font-size:18px;
          margin-left: 30px;
          font-weight: 100;
        }
      }
      .news-content{
        p{
          font-size: 18px;
          color: #313131;
          line-height: 32px;
          text-indent: 36px;
        }
        .img-explain{
          text-align:justify;
          text-align: center;
          font-size:16px;
        }
      }
      .img-box{
        width: 100%;
    display: flex;
    justify-content: center;
        img{
          width:800px;
        }
      }
    }
    .pagination{
      display: flex;
      justify-content: space-between;
      padding: 50px 0 150px;
      border-top: 1px solid #DDDDDD;
      .a-more{
        display: flex;
        flex-direction: column;
        font-size:18px;
        line-height:32px;
        color:rgba(49,49,49,1);
        span{
          display: inline-block;
          width:600px;
          white-space: nowrap;
          text-overflow:ellipsis;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .el-button{
        width: 134px;
        height: 48px;
        border: 1px solid rgba(253,156,2,1);
        opacity: 1;
        border-radius: 10px;
        font-size:24px;
        color:rgba(253,156,2,1);
      }
    }
    // 搜索区域
    .search-right{
      width: 334px;
      .el-input{
        width: 329px;
        height: 45px;
      }
      .el-input__inner{
        border-radius: 5px;
      }
      .el-input__icon{
        font-size: 24px;
      }
      .hot-news{
        .hot-title{
          font-size:24px;
          margin-bottom: 12px;
          color:#313131;
          .hot-icon{
            display: inline-block;
            background: url('../../assets/images/news/hot.png');
            width: 18px;
            height: 22px;
            margin-left: 6px;
          }
        }
        .dg-news{
          display: flex;
          margin-bottom: 22px;
          img{
            width:132px;
            height:76px;
          }
          .news-introduce{
            flex: 1;
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .news-title{
              font-size:16px;
              font-weight: 600;
              color: #313131;
              box-sizing: border-box;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
              overflow: hidden;
            }
            .time{
              font-size:18px;
              color: #B5B5B5;
              font-weight: 100;
            }
          }
          &:hover{
            .news-title{
              color:rgba(253,156,2,1);
            }
          }
        }
      }
    }
  }
}
}
@media screen and (max-width: 1400px) {
  .preview-news{
    width: 100%;
    height: auto;
    background: #fff;
    .main{
      width: 100%;
      height: auto;
      display: flex;
      padding-top: 1rem;
      justify-content: space-between;
      padding:1.07rem .48rem;
      .left{
        flex: 1;
        h3{
          color: #313131;
          font-size:.48rem;
          font-weight: bolder;
          text-align: center;
        }
        .news-setting{
          color: #B5B5B5;
          display: flex;
          font-size:18px;
          .time{
            font-size:.267rem;
            color: #B5B5B5;
            font-weight: 100;
            width: 100%;
            text-align: center;
          }
          .num{
            font-size:18px;
            margin-left: 30px;
            font-weight: 100;
          }
        }
        .news-content{
          width: 100%;
          p{
            font-size: .373rem;
            color: #313131;
            line-height: .7rem;
            text-indent: .747rem;
          }
          .img-explain{
            text-align:justify;
            text-align: center;
            font-size:.267rem;
          }
        }
        .img-box{
          width: 100%;
          display: flex;
          justify-content: center;
          img{
            width:100%;
            height: 100%;
          }
        }
      }
      .pagination{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: .53rem;
        .a-more{
          display: flex;
          flex-direction: column;
          font-size:.32rem;
          span{
            display: inline-block;
            width:8.933rem;
            cursor: pointer;
            color:rgba(49,49,49,1);
            height: auto;
            padding:.267rem .32rem;
            border: 1px solid #8A8684;
            border-radius: .267rem;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;
            margin-bottom: .48rem;
          }
        }
        .el-button{
          width: 100%;
          font-size:.427rem;
          border: 1px solid rgba(253,156,2,1);
          padding:.267rem .32rem;
          border-radius: .267rem;
          color:rgba(253,156,2,1);
        }
      }
      // 搜索区域
      // .search-right{
      //   width: 334px;
      //   .el-input{
      //     width: 329px;
      //     height: 45px;
      //   }
      //   .el-input__inner{
      //     border-radius: 5px;
      //   }
      //   .el-input__icon{
      //     font-size: 24px;
      //   }
      //   .hot-news{
      //     .hot-title{
      //       font-size:24px;
      //       margin-bottom: 12px;
      //       color:#313131;
      //       .hot-icon{
      //         display: inline-block;
      //         background: url('../../assets/images/news/hot.png');
      //         width: 18px;
      //         height: 22px;
      //         margin-left: 6px;
      //       }
      //     }
      //     .dg-news{
      //       display: flex;
      //       margin-bottom: 22px;
      //       img{
      //         width:132px;
      //         height:76px;
      //       }
      //       .news-introduce{
      //         flex: 1;
      //         margin-left: 8px;
      //         display: flex;
      //         flex-direction: column;
      //         justify-content: space-between;

      //         .news-title{
      //           font-size:16px;
      //           font-weight: 600;
      //           color: #313131;
      //           box-sizing: border-box;
      //           text-overflow:ellipsis;
      //           display:-webkit-box;
      //           -webkit-box-orient:vertical;
      //           -webkit-line-clamp:2;
      //           overflow: hidden;
      //         }
      //         .time{
      //           font-size:18px;
      //           color: #B5B5B5;
      //           font-weight: 100;
      //         }
      //       }
      //       &:hover{
      //         .news-title{
      //           color:rgba(253,156,2,1);
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}
</style>
